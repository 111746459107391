// require jQuery normally
const $ = require('jquery');
global.$ = global.jQuery = $;
window.$ = window.jQuery = $;

import "../../../../web/js/jquery-ui/jquery-ui.min.js";

/*** Handle jQuery plugin naming conflict between jQuery UI and Bootstrap ***/
$.widget.bridge('uibutton', $.ui.button);
$.widget.bridge('uitooltip', $.ui.tooltip);

import 'bootstrap';

import 'lightbox2/dist/js/lightbox.min.js';
import 'chosen-js/chosen.jquery.js';
const autosize = require('autosize/dist/autosize.min.js');
window.autosize = autosize;

import 'jquery-tokeninput/src/jquery.tokeninput.js';

$('.multiselect').selectpicker();

const devbridgeAutocomplete = require('devbridge-autocomplete/dist/jquery.autocomplete.js');
window.devbridgeAutocomplete = devbridgeAutocomplete;

const Highcharts = require('highcharts');
window.Highcharts = Highcharts;
